/*
 * Variables
 */

$color-cream: #f6f5f3;
$color-cream-darker: #b4ad9b;
$color-text-muted: #6c757d;
$color-text-black: #444;
$event-natural: #71a699;
$event-accidental: #b8b803;

/*
 * Overrides
 */

html,
* {
  font-family: inter, sans-serif;
}

a,
.btn-link {
  color: $color-text-black;

  &:hover {
    text-decoration: none;
  }
}

html,
body {
  height: 100%;
  background: $color-cream;
}

#root {
  height: 100%;
}

/*
 * Utilities
 */

.cursor-pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

.top-4 {
  top: 1.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-4 {
  bottom: 1.5rem;
}

.left-4 {
  left: 1.5rem;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1.5rem;
}

.mr-n4 {
  margin-right: -1.5rem;
}

.my-6 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mt-6 {
  margin-top: 4rem;
}

.mt-7 {
  margin-top: 6rem;
}

.ml-6 {
  margin-left: 4rem;
}

.width-7 {
  width: 6rem;
}

.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.text-secondary {
  color: #999 !important;
}
a.text-secondary:hover {
  color: #888 !important;
}

.opacity-75 {
  opacity: 0.75;
}

.color-brown {
  color: #855e42;
}

.color-text-black {
  color: $color-text-black;
}

.bg-light-gray {
  background-color: #ddd;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.small {
  font-size: 80%;
}

.icon-1 {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.4em;
}

.icon-label-1 {
  display: inline-block;
  vertical-align: middle;
}

.border-bottom-light {
  border-bottom: 1px solid #aaa;
}

/*
 * Custom components
 */

.RouterContainer {
  height: 100%;
}

.PianoEditor {
  display: flex;
  flex-direction: column;
  height: 100%;

  // Theming ReactPiano
  .ReactPiano__Keyboard {
    border-top: 2px solid #888;
    background: #aaa;
    padding: 1px;
  }
  .ReactPiano__Key {
    &.ReactPiano__Key--disabled {
      cursor: default;
    }
  }
  .ReactPiano__Key--accidental {
    border-radius: 0 0 3px 3px;
    border-top: none;

    &.ReactPiano__Key--active {
      border: none;
      background-color: $event-accidental;
    }
  }
  .ReactPiano__Key--natural {
    border: none;
    border-top: none;
    border-radius: 0 0 2px 2px;
    background-color: #fff;

    &.ReactPiano__Key--active {
      background: $event-natural;
      border-color: $event-natural;
      height: 100%;
    }
  }
  .ReactPiano__NoteLabel--natural {
    margin-bottom: 1px;
  }
  .ReactPiano__NoteLabel--accidental {
    margin-bottom: 1px;
  }
}

.PianoRoll__Event {
  background: $event-natural;
  border-radius: 3px;
  border: 1px solid $color-cream;
}

.PianoRoll__Event--accidental {
  background-color: #b8b803;
  opacity: 0.8;
}

.PianoRoll__Event--selected {
  border-color: #fff;
  background-color: #8ab6b6;
}

.PianoRoll__Event--accidental.PianoRoll__Event--selected {
  background-color: #dbdb81;
}

.PianoRoll__Cursor {
  background-color: #ccc;
  width: 100%;
  height: 2px;
  opacity: 0.5;
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
  border-left: 5px solid #888;
  border-right: 5px solid #888;
}

.Nav {
  background: $color-cream;
}

.Nav__logo {
  font-size: 1.1rem;
  color: $color-text-black;
}
.Nav__logo:hover {
  text-decoration: none;
}

.PianoEmbed {
  background: #fff;
}

.PianoEmbed__Overlay {
  background: rgba(#000, 0.5);
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PianoEmbed__Overlay--hidden {
  background: transparent;
}

.GifBorder {
  background: #333;
  padding: 8px;
  border: 2px solid #000;
  border-radius: 4px;
}

.ShortcutIcon {
  border: 1px solid #aaa;
  border-bottom: 2px solid #aaa;
  padding: 5px 9px;
  border-radius: 4px;
}
